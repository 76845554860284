/* Mail Sidebar */

.email-wrapper {
	.mail-sidebar {
		@media (max-width:769px) {
			position: relative;
		}
		@include media-breakpoint-down(sm) {
			position: fixed;
			z-index: 99;
			background: color(white);
			width: 45%;
			min-width: 300px;
			left: -100%;
			display: block;
			transition: 0.4s ease;
			-webkit-transition: 0.4s ease;
			-moz-transition: 0.4s ease;
		}

		.menu-bar {
			width: 100%;
			float: right;
			height: 100%;
			min-height: 100%;
			@include media-breakpoint-down(sm) {
				min-height: 100vh;
				max-height: 100%;
				height: auto;
				overflow-y: auto;
				overflow-x: hidden;
			}

			.menu-items {
				padding: 0;
				margin-bottom: 0;
				height: auto;
				list-style-type: none;

				li {
					padding: 10px 15px;
					transition: 0.4s;
					position: relative;
					@include display-flex;
					@include align-items(center);
					@include justify-content(space-between);

					&:hover {
						background: rgba(240, 244, 249, 0.8);
					}

					a {
						color: lighten($darkslategray, .73);
						font-size: $default-font-size;
						text-decoration: none;

						i {
							margin-right: 8px;
							font-size: $default-font-size;
							line-height: 1.5;
						}
					}

					&.active {
						background: color(gray-lightest);
						@include border-radius(4px);

						a {
							color: theme-color(primary);
						}
					}

					&.compose {
						&:hover {
							background: transparent;
						}
					}
				}
			}

			.online-status {
				margin-top: 1rem;

				.chat {
					font-size: $default-font-size;
					color: theme-color(primary);
					margin-bottom: 0;
					font-weight: 600;
				}

				.status {
					height: 10px;
					width: 10px;
					@include border-radius(100%);
					display: inline-flex;
					justify-content: flex-start;
					transform: translateX(-43px) translateY(2px);

					&:after {
						font-size: 12px;
						color: $darkslategray;
						margin: -2px 0 0 18px;
					}
				}

				.status.offline {
					background: theme-color(danger);

					&:after {
						content: "Offline";
					}
				}

				.status.online {
					background: theme-color(success);

					&:after {
						content: "Online";
					}
				}
			}

			.profile-list {
				padding: 10px 0;
			}

			.profile-list-item {
				border-bottom: 1px solid $border-color;
				padding: 6px 0;
				display: block;

				&.active {
					background-color: #eee;
				}
				

				a {
					text-decoration: none;
					@extend .d-flex;

					.pro-pic {
						@include display-flex;
						@include align-items(center);
						padding: 0;
						width: 20%;
						max-width: 40px;

						img {
							max-width: 100%;
							width: 100%;
							@include border-radius(100%);
						}
					}

					.user {
						width: 100%;
						@extend .d-flex;
						@extend .flex-column;
						

						.u-name {
							font-weight: 400;
							font-size: $default-font-size;
							line-height: 1;
							@extend %ellipsor;
							color: color(black);
							margin-bottom: 0px;
							padding: 10px 5px;
						}

						.u-designation {
							font-size: calc(#{$default-font-size} - 0.1rem);
							@extend %ellipsor;
							@extend .text-muted;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.sidebar.open {
		left: 0;
	}
}